import * as XLSX from 'xlsx';

const downloadExcel = (data: any, headerMapping?: any) => {
  const worksheet = XLSX.utils.json_to_sheet(
    data.map((row: any) => {
      let newRow: any = {};
      Object.keys(row).forEach((key) => {
        newRow[headerMapping ? headerMapping[key] : key] = row[key];
      });
      return newRow;
    })
  );

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = 'data.xlsx';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const Table = ({ data, headerMapping }: any) => {
  if (!data || !data.length) {
    return <p>No data available</p>;
  }

  const headers = Object.keys(data?.[0]);

  return (
    <div className="flex flex-col items-end gap-y-2">
      <div
        role="button"
        onClick={() => downloadExcel(data, headerMapping)}
        className="px-4 flex justify-center items-center bg-accent rounded-lg hover:bg-primary gap-x-2 py-2"
      >
        <p className={`text-lg font-extrabold lg:text-sm text-white`}>
          Download as XLSX
        </p>
      </div>
      <p className="text-xs my-2">
        Note: Any emissions data in tabular form is always fixed at kg CO2e
      </p>
      <table className="divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {headerMapping ? headerMapping[header] : header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((row: any, rowIndex: number) => (
            <tr key={rowIndex}>
              {headers.map((header, index) => (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  {!isNaN(parseFloat(row[header])) && isFinite(row[header])
                    ? parseFloat(row[header]).toFixed(2)
                    : row[header]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
