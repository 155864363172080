import React from 'react';
import Pagination from '../../components/Pagination/Pagination';
import { Supplier } from '../../types/Supplier';
import { classNames } from '../../utils/utils';

export interface SuppliersTableProps {
  suppliers: Supplier[];
  count: number;
  setPage: (page: number) => void;
  pageNumber: number;
  pageSize: number;
}

export default function SuppliersTable({
  suppliers,
  setPage,
  pageNumber,
  pageSize,
  count,
}: SuppliersTableProps) {
  const countryPillColours = new Map<string, string>();
  const sectorsPillColous = new Map<string, string>();
  const colours = [
    'bg-blue-100 text-blue-800 ring-blue-500/10',
    'bg-green-100 text-green-800 ring-green-500/10',
    'bg-yellow-100 text-yellow-800 ring-yellow-500/10',
    'bg-indigo-100 text-indigo-800 ring-indigo-500/10',
    'bg-pink-100 text-pink-800 ring-pink-500/10',
    'bg-red-100 text-red-800 ring-red-500/10',
    'bg-purple-100 text-purple-800 ring-purple-500/10',
  ];

  const getSectorsSubSectorsPillColour = (name: string) => {
    const existing = sectorsPillColous.get(name);
    if (existing) {
      return existing;
    }

    const colour = colours[sectorsPillColous.size % colours.length];
    sectorsPillColous.set(name, colour);
    return colour;
  };
  const getCountryPillColour = (name: string) => {
    const existing = countryPillColours.get(name);
    if (existing) {
      return existing;
    }

    const colour = colours[countryPillColours.size % colours.length];
    countryPillColours.set(name, colour);
    return colour;
  };

  return (
    <>
      <table className="divide-y divide-gray-300 w-full">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              Supplier Name
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Country
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Address(s)
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Sector(s)
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Sub-Sector(s)
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {suppliers.map((supplier) => (
            <tr key={supplier.id}>
              <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                {supplier?.name}
                <dl className="font-normal lg:hidden">
                  <dd className="mt-1 truncate text-gray-700">
                    {supplier.countries?.map((country) => (
                      <span
                        key={country.name}
                        className={classNames(
                          'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ',
                          getCountryPillColour(country.name)
                        )}
                      >
                        {country.name}
                      </span>
                    ))}
                  </dd>
                </dl>
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                <div className="flex flex-wrap gap-x-2 gap-y-2">
                  {supplier.countries?.map((country) => (
                    <span
                      key={country.name}
                      className={classNames(
                        'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ',
                        getCountryPillColour(country.name)
                      )}
                    >
                      {country.name}
                    </span>
                  ))}
                </div>
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {supplier.locations?.map((location) => (
                  <div key={location.location} className="mt-2 ml-2">
                    <p className="text-sm leading-7 text-gray-600">
                      {location.location}
                    </p>
                  </div>
                ))}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                <div className="flex flex-wrap gap-x-2 gap-y-2">
                  {supplier.sectors?.map((sector) => (
                    <span
                      key={sector}
                      className={classNames(
                        'inline-flex items-center rounded-md mr-1 px-2 py-1 text-xs font-medium ring-1 ring-inset ',
                        getSectorsSubSectorsPillColour(sector)
                      )}
                    >
                      {sector}
                    </span>
                  ))}
                </div>
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                <div className="flex flex-wrap gap-x-2 gap-y-2">
                  {supplier.subsectors?.map((sector) => (
                    <span
                      key={sector}
                      className={classNames(
                        'inline-flex items-center rounded-md mr-1 px-2 py-1 text-xs font-medium ring-1 ring-inset ',
                        getSectorsSubSectorsPillColour(sector)
                      )}
                    >
                      {sector}
                    </span>
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        goToPage={setPage}
        total={count}
        currentPage={pageNumber}
        pageSize={pageSize}
      />
    </>
  );
}
