import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import Table from './table';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const PDFDocument = ({
  charts = [],
  activeOrganisation,
  dateSelection,
}: any) => {
  return (
    <Document>
      {charts.map((chart: any, index: number) => (
        <Page size="A4" style={styles.page} key={index}>
          <View style={styles.section}>
            <Image style={{ width: 100 }} src={'/logo.png'} />
            <View
              style={{
                marginVertical: 5,
                marginTop: 10,
                borderBottom: '1px solid #eee',
              }}
            />
            <Text style={{ marginVertical: 8, fontSize: 14 }}>
              Organisation: {activeOrganisation?.companyName}
            </Text>
            <View
              style={{ marginVertical: 5, borderBottom: '1px solid #eee' }}
            />
            <Text
              style={{
                marginTop: 10,
                marginBottom: 5,
                fontSize: 14,
                fontStyle: 'bold',
              }}
            >
              {chart.title}
            </Text>
            <Text style={{ marginBottom: 10, fontSize: 12 }}>
              Time Period: {dateSelection}
            </Text>
            <View
              style={{
                marginVertical: 5,
                borderBottom: '1px solid #eee',
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
              }}
            />
            {chart.image ? (
              <Image
                style={{ ...(chart.imageStyle ?? {}), marginTop: 5 }}
                src={chart.image}
              ></Image>
            ) : null}
            {chart?.table?.data ? (
              <View style={{ marginTop: 20 }}>
                <Table
                  headerMapping={chart?.table?.headerMapping}
                  data={chart?.table?.data}
                />
              </View>
            ) : null}
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default PDFDocument;
