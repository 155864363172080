import { gql } from '@apollo/client';

export const getImports = gql`
  query GetImports($activeOrganisation: String, $input: GenericFindOptions) {
    getImports(activeOrganisation: $activeOrganisation, input: $input) {
      count
      results {
        id
        status
        statusMessage
        fileName
        totalRows
        name
        completedRows
        missingDataRows
        createdBy {
          email
        }
        createdAt
      }
      response {
        message
        success
      }
    }
  }
`;
