import { gql } from '@apollo/client';

export const saveDataToImport = gql`
  mutation SaveDataToImport(
    $activeOrganisation: String
    $input: SaveDataToImportInput
  ) {
    saveDataToImport(activeOrganisation: $activeOrganisation, input: $input) {
      success
      message
    }
  }
`;
