import React, { useState } from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../utils/firebaseConfig';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isResetPasswordSuccess, setIsResetPasswordSuccess] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      // Redirect or perform post-login actions here
      console.log(result);
      navigate('/');
    } catch (error: any) {
      setErrorMessage('Invalid Email or Password. Please try again.');
    }
  };

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setIsResetPasswordSuccess(true);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <img src="https://trackcarbon.com/wp-content/themes/base-c/images/track-carbon-logo.svg" alt="Company Logo" style={{ height: '50px' }} />
      </div>
      <div style={{ display: 'inline-block', border: '1px solid black', padding: '30px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '500px' }}>
        {isResetPassword && (
          <div>
            <h1 style={{ fontSize: '2em', marginBottom: '20px' }}>Reset your password</h1>
            <form onSubmit={handleResetPassword}>
              <div>
                <label style={{ color: 'grey', float: 'left' }} htmlFor="email">Email</label>
                <input
                  required
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ display: 'block', margin: '10px 0', width: '100%', padding: '8px' }}
                />
              </div>
              <button type="submit" style={{ backgroundColor: 'black', color: 'white', padding: '10px 20px', border: 'none', cursor: 'pointer', marginTop: '10px', width: '100%' }}><strong>Send reset link</strong></button>
              <button type="button" onClick={() => setIsResetPassword(false)} style={{ backgroundColor: 'transparent', color: 'black', padding: '10px 20px', border: 'none', cursor: 'pointer', marginTop: '10px', width: 'auto', textDecoration: 'underline' }}>Back to Login</button>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </form>
          </div>
        )}
        {!isResetPassword && (
          <div>
            <h1 style={{ fontSize: '2em', marginBottom: '20px' }}>Sign in to your account</h1>
            <form onSubmit={handleLogin}>
              <div>
                <label style={{ color: 'grey', float: 'left' }} htmlFor="email">Username:</label>
                <input
                  required
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ display: 'block', margin: '10px 0', width: '100%', padding: '8px' }}
                />
              </div>
              <div>
                <label style={{ color: 'grey', float: 'left' }} htmlFor="password">Password:</label>
                <input
                  required
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ display: 'block', margin: '10px 0', width: '100%', padding: '8px' }}
                />
                <div style={{ float: 'left', paddingBottom: '10px' }}>
                  <input
                    type="checkbox"
                    id="showPassword"
                    checked={showPassword}
                    onChange={() => setShowPassword(!showPassword)}
                  />
                  <label style={{ padding: '5px', color: 'grey' }} htmlFor="showPassword">Show Password</label>
                </div>
              </div>
              <button type="submit" style={{ backgroundColor: 'black', color: 'white', padding: '10px 20px', border: 'none', cursor: 'pointer', marginTop: '10px', width: '100%' }}><strong>Sign In</strong></button>
              <button type="button" onClick={() => setIsResetPassword(true)} style={{ backgroundColor: 'transparent', color: 'black', padding: '10px 20px', border: 'none', cursor: 'pointer', marginTop: '10px', width: 'auto', textDecoration: 'underline' }}>Reset Password</button>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </form>
          </div>
        )}
      </div>
      <p style={{ marginTop: '20px' }}>All rights reserved</p>
    </div>
  );
};

export default Login;