import { ErrorMessage, Field } from 'formik';
import { classNames } from '../../../utils/utils';

interface TextInputProps {
  label?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  inputPrefix?: string;
  id: string;
  autoComplete?: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const TextInput = (props: TextInputProps) => {
  const {
    label = '',
    type = 'text',
    placeholder = '',
    disabled = false,
    autoComplete,
    value,
    inputPrefix,
    setFieldValue,
    id,
  } = props;
  return (
    <>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <ErrorMessage name={id} component="div" className="text-red-700" />
      <div className="mt-2">
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-600 sm:max-w-md">
          {inputPrefix && (
            <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
              {inputPrefix}
            </span>
          )}
          <Field
            type={type}
            name={id}
            id={id}
            value={value}
            disabled={disabled}
            className={classNames(
              disabled ? 'bg-gray-50' : 'bg-transparent',
              'block flex-1 border-0 py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
            )}
            placeholder={placeholder}
            autoComplete={autoComplete}
            onChange={(event: any) => setFieldValue(id, event.target.value)}
          />
        </div>
      </div>
    </>
  );
};
