import { UserTable } from './UserTable';
import React, { useContext, useEffect, useState } from 'react';
import { classNames } from '../../utils/utils';
import { FirebaseAuthContext } from '../../context/FirebaseAuthContext';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { getUsers } from '../../graphql/queries/getUsers';
import { createUser } from '../../graphql/mutations/createUser';
import { deleteUser } from '../../graphql/mutations/deleteUser';
import { usePagination } from '../../context/PaginationContext';
import { Card } from '../../components/Layout/Card/Card';
import { OrganisationContext } from '../../context/OrganisationContext';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../utils/firebaseConfig';

export default function Users() {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [error, setError] = useState('');
  const { activeOrganisation } = useContext(OrganisationContext);

  const { limit, offset, setOffset } = usePagination();

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
    window.scrollTo(0, 0);
  };

  const { user } = useContext(FirebaseAuthContext);

  const { useQuery, useMutation } = useOrganisationAwareApollo();

  const { data, refetch } = useQuery(getUsers, {
    variables: {
      input: {
        limit,
        offset,
      },
    },
  });

  const [saveUser, { loading: isSavingUser }] = useMutation(createUser, {
    variables: {
      input: {
        email: email,
      },
    },
    onCompleted: async (data) => {
      setError('');
      if (data.createUser.success) {
        try {
          await sendPasswordResetEmail(auth, email)
        } catch (error) {
          console.error(error)
        }
        refetch();
        setEmail('');
      } else {
        setError(data.createUser.message);
        refetch();
        // TODO show error
      }
    },
  });

  const [onDeleteUser, { loading: isDeletingUser }] = useMutation(deleteUser, {
    variables: {
      input: {
        email: email,
      },
    },
    onCompleted: (data) => {
      setError('');
      if (data.deleteUser.success) {
        refetch();
      } else {
        setError(data.deleteUser.message);
        refetch();
      }
    },
  });

  const onSaveUser = () => {
    if (!isEmailValid || email.length === 0) {
      return;
    }
    saveUser();
  };

  const users = data?.getUsers?.results ?? [];
  const count = data?.getUsers?.count ?? 0;

  useEffect(() => {
    if (user.isAuthenticated) {
      refetch();
    }
  }, [user.isAuthenticated, limit, offset, activeOrganisation]);

  const onChangeEmail = (e: string) => {
    setEmail(e.toLowerCase().trim());
    // check its a valid email using regex

    if (e.length === 0) {
      setIsEmailValid(true);
      return;
    }
    const re = /\S+@\S+\.\S+/;
    setIsEmailValid(re.test(String(e).toLowerCase()));
  };

  return (
    <>
      <Card>
        <Card.Header>
          <div className="bg-white px-4 sm:px-6">
            <div className="flex sm:items-end items-center flex-wrap justify-between">
              <div className="flex flex-wrap">
                <h3 className="font-semibold">Users</h3>
              </div>
              <div className="flex flex-wrap"></div>
            </div>
            <div className="text-right mt-2">
              {!isDeletingUser || !isSavingUser ? (
                (!isEmailValid || error != '') && (
                  <p className="text-red-600">
                    {!isEmailValid ? 'Email is not valid' : `Error: ${error}`}
                  </p>
                )
              ) : (
                <div className="border-dashed border-2 rounded-full animate-spin w-5 h-5" />
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <p className="text-sm text-gray-700">Add a user below:</p>
          <div className="py-2 pb-6 border-b sm:flex sm:items-center">
            <div className="w-full md:w-96">
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                value={email}
                onChange={(e) => {
                  onChangeEmail(e.target.value);
                }}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                placeholder="you@example.com"
              />
            </div>
            <button
              type="submit"
              className={classNames(
                'mt-3 inline-flex w-full items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  sm:ml-3 sm:mt-0 sm:w-auto',
                !isEmailValid
                  ? 'cursor-not-allowed bg-red-600'
                  : 'bg-primary hover:bg-green-600 focus-visible:outline-green-600'
              )}
              disabled={!isEmailValid || isSavingUser}
              onClick={() => {
                onSaveUser();
              }}
            >
              {isSavingUser ? (
                <div className="border-dashed border-2 rounded-full animate-spin w-5 h-5" />
              ) : (
                'Add '
              )}
            </button>
          </div>
          <UserTable
            users={users}
            count={count}
            setPage={handlePageChange}
            pageSize={limit}
            isDeleting={isDeletingUser}
            deleteUser={onDeleteUser}
            pageNumber={Math.floor(offset / limit) + 1}
          />
        </Card.Body>
      </Card>
    </>
  );
}
