import React, { Fragment } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import { ProductPurchase } from '../../types/ProductPurchase';
import moment from 'moment/moment';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../utils/utils';

export interface RawDataTableProps {
  productPurchases: ProductPurchase[];
  navigate: any;
  setImportId: any;
  count: number;
  setPage: (page: number) => void;
  pageNumber: number;
  pageSize: number;
}

export default function RawDataTable({
  productPurchases,
  setPage,
  pageNumber,
  pageSize,
  count,
  navigate,
  setImportId,
}: RawDataTableProps) {
  return (
    <>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              Description
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Quantity
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Unit Price
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Date Of Purchase
            </th>

            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Supplier
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Supplier Location
            </th>

            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Emissions Kg CO2e
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              File Name
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Upload Date
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {productPurchases.map((productPurchaseRow) => (
            <tr key={productPurchaseRow.id}>
              <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                {productPurchaseRow?.product?.description}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                {productPurchaseRow.quantity}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                £{Number(productPurchaseRow.unitPrice).toFixed(2) || '-'}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                {moment(
                  new Date(parseInt(productPurchaseRow?.dateOfPurchase || ''))
                ).format('YYYY-MM')}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {productPurchaseRow?.product?.supplier?.name}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {productPurchaseRow?.supplierLocation}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {Number(productPurchaseRow?.emissionTotal).toFixed(2) || '-'}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {productPurchaseRow?.import?.fileName}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {moment(
                  new Date(parseInt(productPurchaseRow?.import?.createdAt))
                ).format('LLL')}
              </td>
              <td className="py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-0">
                <Menu as="div" className="relative flex-none">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            role="button"
                            onClick={async () => {
                              navigate(
                                `/data?importId=${productPurchaseRow?.import?.id}`
                              );
                              setImportId(productPurchaseRow?.import?.id);
                            }}
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900'
                            )}
                          >
                            View this Import
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        goToPage={setPage}
        total={count}
        currentPage={pageNumber}
        pageSize={pageSize}
      />
    </>
  );
}
