import React, { useContext, useEffect } from 'react';
import { FirebaseAuthContext } from './context/FirebaseAuthContext';
import Login from './components/Login/Login';
import RootLayout from './app/layout';
import { ApolloProvider } from '@apollo/client';
import { useApolloClient } from './hooks/useApolloClient';
import NotificationWrapper from './components/Notification/NotificationWrapper';
import { ContextWrapper } from './context/ContextWrapper';

function App() {
  const { user, isLoading } = useContext(FirebaseAuthContext);
  const apolloClient = useApolloClient();

  useEffect(() => {
    console.log('Auth State:', { user, isLoading });
  }, [user, isLoading]);

  // Show loading state while checking auth
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Show login if not authenticated
  if (!user.isAuthenticated) {
    console.log('User not authenticated, showing login');
    return <Login />;
  }

  console.log('User authenticated, showing main app');
  return (
    <ApolloProvider client={apolloClient}>
      <div className="App h-screen">
        <RootLayout />
        <NotificationWrapper />
      </div>
    </ApolloProvider>
  );
}

const AppWrapper = () => {
  return (
    <ContextWrapper>
      <App />
    </ContextWrapper>
  );
};

export default AppWrapper;