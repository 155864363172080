import { Menu, Transition } from '@headlessui/react';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/solid';
import { Fragment, useContext } from 'react';
import { auth } from '../../utils/firebaseConfig';
import { FirebaseAuthContext } from '../../context/FirebaseAuthContext';
import { classNames } from '../../utils/utils';
import OrganisationPicker from './OrganisationPicker';

export default function Header({ children }: any) {
  const { user } = useContext(FirebaseAuthContext);
  return (
    <header className="bg-gray-50 fixed z-50 border-accent shadow-sm w-full">
      <nav
        className="mx-auto flex max-w-8xl items-center justify-between py-1 px-4 lg:px-8"
        aria-label="Global"
      >
        <div className="flex gap-10 items-center">
          <a href="/">
            <div className="flex flex-row items-center gap-x-2 justify-center w-[160px]">
              <img
                className="transition-all"
                width={160}
                src="https://trackcarbon.com/wp-content/themes/base-c/images/track-carbon-logo.svg"
                alt="Track Carbon Logo"
              />
            </div>
          </a>
          <div className="font-light hidden border-l border-dashed px-6 md:flex flex-col text-sm text-gray-600">
            <div className="font-bold text-base">Scope 3</div>
            <div className="text-primary -mt-1">Emissions</div>
          </div>
        </div>
        {children}
        <div className="mr-2 lg:mx-10 border-l border-r border-dashed">
          <OrganisationPicker pickerAlign="right" />
        </div>
        <Menu as="div" className="">
          <Menu.Button className="-m-1.5 flex items-center p-1.5">
            <span className="sr-only">Open user menu</span>
            <div className="h-10 w-10 ">
              <img
                className="h-10 w-10 rounded-lg bg-gray-50 object-cover"
                src={`https://www.gravatar.com/avatar/${user?.username ?? 'test@email.com'
                  }?d=mp`}
                alt=""
              />
            </div>
            <span className="hidden lg:flex lg:items-center">
              <ChevronDoubleDownIcon
                className="ml-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              {[{ name: 'Sign Out', href: '#' }].map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <a
                      onClick={() => auth.signOut()}
                      className={classNames(
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                      )}
                    >
                      {item.name}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </nav>
    </header>
  );
}
