export enum DateSelection {
  LAST_THREE_MONTHS = 'Last 3 Months',
  LAST_SIX_MONTHS = 'Last 6 Months',
  LAST_TWELVE_MONTHS = 'Last 12 Months',
  CURRENT_QUARTER = 'Current Quarter',
  LAST_COMPLETE_QUARTER = 'Last Complete Quarter',
  LAST_YEAR = 'Last Year',
  LAST_MONTH = 'Last Month',
  ALL_TIME = 'All Time',
}
