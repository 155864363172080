import { gql } from '@apollo/client';

export const getMyOrganisations = gql`
  query getMyOrganisations {
    getMyOrganisations {
      count
      results {
        id
        companyName
        logoUrl
      }
      response {
        success
        message
      }
    }
  }
`;
