import { gql } from '@apollo/client';

export const getSuppliers = gql`
  query GetSuppliers($activeOrganisation: String, $input: GenericFindOptions) {
    getSuppliers(activeOrganisation: $activeOrganisation, input: $input) {
      count
      results {
        name
        sectors
        subsectors
        countries {
          name
        }
        locations {
          location
        }
        modesOfTransport {
          name
        }
      }
      response {
        success
      }
    }
  }
`;
