import { User as FirebaseUser, onAuthStateChanged } from 'firebase/auth';
import * as React from 'react';
import { auth } from '../utils/firebaseConfig';
import md5 from 'md5';

interface User {
  isAuthenticated: boolean;
  attributes: {
    email?: string | null;
    picture?: string;
  };
  username?: string | null;
  id?: string;
}

interface AuthContextProps {
  user: User;
  isLoading: boolean;
}

const UNAUTHENTICATED_USER = {
  attributes: {},
  isAuthenticated: false,
  username: '',
  id: '',
  roles: [],
};

export const FirebaseAuthContext = React.createContext<AuthContextProps>({
  user: UNAUTHENTICATED_USER,
  isLoading: true,
});

export const FirebaseAuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [user, setUser] = React.useState<User>(UNAUTHENTICATED_USER);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser: FirebaseUser | null) => {
      if (firebaseUser) {
        setUser({
          isAuthenticated: true,
          attributes: {
            email: firebaseUser.email,
            picture: firebaseUser.photoURL || 
              `https://gravatar.com/avatar/${md5(firebaseUser.email || '')}?d=mp`,
          },
          username: firebaseUser.email,
          id: firebaseUser.uid,
        });
      } else {
        setUser(UNAUTHENTICATED_USER);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <FirebaseAuthContext.Provider value={{ user, isLoading }}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};