import { gql } from '@apollo/client';

export const deleteImport = gql`
  mutation DeleteImport(
    $activeOrganisation: String
    $input: DeleteImportInput
  ) {
    deleteImport(activeOrganisation: $activeOrganisation, input: $input) {
      message
      success
    }
  }
`;
