import { gql } from '@apollo/client';

export const deleteUser = gql`
  mutation DeleteUser($activeOrganisation: String, $input: DeleteUserInput) {
    deleteUser(activeOrganisation: $activeOrganisation, input: $input) {
      success
      message
    }
  }
`;
