import { useContext, useEffect } from 'react';
import { Card } from '../../../components/Layout/Card/Card';
import { FirebaseAuthContext } from '../../../context/FirebaseAuthContext';
import { OrganisationContext } from '../../../context/OrganisationContext';
import { usePagination } from '../../../context/PaginationContext';
import { deleteDataFromImport } from '../../../graphql/queries/deleteDataFromImport';
import { getImportFiles } from '../../../graphql/queries/getImportFiles';
import { getImports } from '../../../graphql/queries/getImports';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import ImportTable from './ImportTable';
import { ImportNew } from './new/page';

export const ImportView = () => {
  const { activeOrganisation } = useContext(OrganisationContext);
  const { user } = useContext(FirebaseAuthContext);
  const { useQuery, useMutation, useLazyQuery } = useOrganisationAwareApollo();
  const { limit, offset, setOffset } = usePagination();
  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
    window.scrollTo(0, 0);
  };

  const [deleteImport] = useMutation(deleteDataFromImport, {});

  const [getURLs] = useLazyQuery(getImportFiles, {});
  const getDownloadUrls = async (id: string, setImportFiles: any) => {
    const { data } = await getURLs({
      variables: {
        input: {
          id,
        },
      },
    });
    setImportFiles({
      rawDataUrl: data?.getImportFiles?.rawDataURL ?? '',
      emissionsDataUrl: data?.getImportFiles?.emissionsDataURL ?? '',
    });
  };

  const deleteImportData = async (id: string) => {
    await deleteImport({
      variables: {
        input: {
          id,
        },
      },
    });
    await refetch();
  };

  const { data, refetch } = useQuery(getImports, {
    variables: {
      input: {
        limit,
        offset,
      },
    },
    fetchPolicy: 'network-only',
  });

  const tableData = data?.getImports?.results ?? [];
  const count = data?.getImports?.count ?? 0;

  useEffect(() => {
    if (user.isAuthenticated) {
      refetch();
    }
  }, [user.isAuthenticated, limit, offset, activeOrganisation]);

  return (
    <>
      <ImportNew onImportComplete={refetch} showProgressButton={false} />
      <Card>
        <Card.Header>
          <div className="bg-white px-4 sm:px-6">
            <div className="flex sm:items-end items-center flex-wrap justify-between">
              <div className="flex flex-wrap">
                <h3 className="font-semibold">Imports</h3>
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <ImportTable
            imports={tableData}
            count={count}
            setPage={handlePageChange}
            pageNumber={Math.floor(offset / limit) + 1}
            pageSize={limit}
            deleteImportData={deleteImportData}
            getDownloadUrls={getDownloadUrls}
          />
        </Card.Body>
      </Card>
    </>
  );
};
