import { gql } from '@apollo/client';

export const createImport = gql`
  mutation CreateImport(
    $activeOrganisation: String
    $input: CreateImportInput
  ) {
    createImport(activeOrganisation: $activeOrganisation, input: $input) {
      id
      success
      message
      presignedUrl
    }
  }
`;
