import { useContext } from 'react';
import { NotificationComponent } from './NotificationComponent';
import { NotificationContext } from '../../context/NotificationContext';

export default function NotificationWrapper() {
  const { notifications, removeNotification } = useContext(NotificationContext);

  return (
    <>
      <div
        aria-live="assertive"
        className="mt-20 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {notifications?.map((notification) => {
            return (
              <NotificationComponent
                dismiss={() => {
                  removeNotification(notification);
                }}
                notification={notification}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}
