import { Dialog, Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import {
  DocumentArrowDownIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import React, { Fragment, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../components/Pagination/Pagination';
import { FirebaseAuthContext } from '../../../context/FirebaseAuthContext';
import { Import } from '../../../types/Import';
import { classNames } from '../../../utils/utils';
export interface ImportTableProps {
  imports: Import[];
  count: number;
  setPage: (page: number) => void;
  deleteImportData: (id: string) => void;
  getDownloadUrls: (id: string, setDownloadUrls: any) => void;
  pageNumber: number;
  pageSize: number;
}

export default function ImportTable({
  imports,
  setPage,
  pageNumber,
  pageSize,
  count,
  deleteImportData,
  getDownloadUrls,
}: ImportTableProps) {
  const { user } = useContext(FirebaseAuthContext);

  const isAdministrator = true // change this 
  // user?.roles?.some(
  //   (role) => role.id === 'SUPER_ADMIN'
  // );

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showDownloadModal, setShowDownloadModal] = React.useState(false);
  const [fileDownloadUrls, setFileDownloadUrls] = React.useState<{
    rawDataUrl: string;
    emissionsDataUrl: string;
  }>({
    rawDataUrl: '',
    emissionsDataUrl: '',
  });
  const cancelButtonRef = useRef(null);
  const [selectedId, setSelectedId] = React.useState('');
  const navigate = useNavigate();

  return (
    <>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              Name
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Status
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              CSV Rows
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Created
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Created By
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0"></th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {imports.map((importRow) => (
            <tr key={importRow.id}>
              <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                {importRow.name || importRow.fileName}
                <dl className="font-normal lg:hidden">
                  <dt className="sr-only">Created At</dt>
                  <dd className="mt-1 truncate text-gray-700">
                    {moment(new Date(parseInt(importRow.createdAt))).fromNow()}
                  </dd>
                  <dt className="sr-only sm:hidden">Status</dt>
                  <dd className="mt-1 truncate text-gray-500 sm:hidden">
                    {importRow.status}
                  </dd>
                </dl>
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {importRow.status}
                <dl className="font-normal lg:hidden">
                  <dt className="sr-only">Number of Rows</dt>
                  <dd className="mt-1 truncate text-gray-500">
                    # Rows: {importRow.totalRows || 0}
                  </dd>
                  <dt className="sr-only sm:hidden">Status</dt>
                  <dd className="mt-1 truncate text-gray-500 sm:hidden">
                    {importRow.status}
                  </dd>
                </dl>
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                {importRow.totalRows || 0}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                {moment(new Date(parseInt(importRow.createdAt))).format('LLL')}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                {importRow.createdBy?.email}
              </td>
              <td className="px-3 py-4 text-left text-sm font-medium sm:pr-0">
                <div
                  role="button"
                  className={classNames(
                    'mt-3 inline-flex w-full items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  sm:ml-3 sm:mt-0 sm:w-auto',
                    'bg-primary hover:bg-green-600 focus-visible:outline-green-600'
                  )}
                  onClick={() => navigate(`/data?importId=${importRow.id}`)}
                >
                  View Data
                </div>
              </td>
              <td className="py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-0">
                <Menu as="div" className="relative flex-none">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            role="button"
                            onClick={async () => {
                              await getDownloadUrls(
                                importRow.id,
                                setFileDownloadUrls
                              );
                              console.log(fileDownloadUrls);
                              setShowDownloadModal(true);
                            }}
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900'
                            )}
                          >
                            Download
                          </div>
                        )}
                      </Menu.Item>
                      {isAdministrator && (
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              role="button"
                              onClick={() => {
                                navigate(`/data/import/${importRow.id}`);
                              }}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                              )}
                            >
                              Upload Calculations
                            </div>
                          )}
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            role="button"
                            onClick={() => {
                              setSelectedId(importRow.id);
                              setShowDeleteModal(true);
                            }}
                            className={classNames(
                              active ? 'bg-red-200' : '',
                              'block px-3 py-1 text-sm leading-6 text-red-700'
                            )}
                          >
                            Delete
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        goToPage={setPage}
        total={count}
        currentPage={pageNumber}
        pageSize={pageSize}
      />
      <Transition.Root show={showDeleteModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setShowDeleteModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Delete Import
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete import data? All of
                          the import data will be deleted and you will need to
                          re-import to see it again.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={async () => {
                        await deleteImportData(selectedId);
                        setShowDeleteModal(false);
                        setSelectedId('');
                      }}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setShowDeleteModal(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={showDownloadModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setShowDownloadModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Download Import
                      </Dialog.Title>
                      <div className="mt-6 flex justify-around">
                        {fileDownloadUrls.rawDataUrl && (
                          <div
                            onClick={() => {
                              window.open(
                                fileDownloadUrls.rawDataUrl,
                                '_blank'
                              );
                              setShowDownloadModal(false);
                            }}
                          >
                            <DocumentArrowDownIcon
                              className="mx-auto h-12 w-12 text-gray-300"
                              aria-hidden="true"
                            />
                            <p>File without Emissions Data</p>
                          </div>
                        )}
                        {fileDownloadUrls.emissionsDataUrl && (
                          <div
                            onClick={() => {
                              window.open(
                                fileDownloadUrls.emissionsDataUrl,
                                '_blank'
                              );
                              setShowDownloadModal(false);
                            }}
                          >
                            <DocumentArrowDownIcon
                              className="mx-auto h-12 w-12 text-gray-300"
                              aria-hidden="true"
                            />
                            <p className="max-w-50%">
                              File with Emissions Data
                            </p>
                          </div>
                        )}
                        {!fileDownloadUrls.emissionsDataUrl &&
                          !fileDownloadUrls.rawDataUrl && (
                            <div>
                              <ExclamationTriangleIcon
                                className="mx-auto h-12 w-12 text-red-600"
                                aria-hidden="true"
                              />
                              <p className="max-w-50%">No data to download</p>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setShowDownloadModal(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
