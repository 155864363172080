import { ChartType, DashboardWidget } from '../../types/Dashboard';

import { AdjustmentsHorizontalIcon } from '@heroicons/react/20/solid';
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
} from '@heroicons/react/24/solid';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import React, { useEffect, useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { CustomLegend } from './CustomLegend';
import { getChartData } from './chartDataMapping';
import Table from './table';

const overrideTickFont = {
  ticks: {
    font: {
      family: 'Poppins',
      size: 12,
    },
  },
};

interface DashboardWidgetsProps {
  filterKey?: string;
  chartRefs?: any;
  setChartRefs?: (ref: any) => void;
  handleLegendItemClick?: (arg?: any, otherArg?: any) => void;
  rawData?: any;
  datasetVisibility?: any;
  dashboardWidgets: DashboardWidget[];
}

export default function DashboardWidgets({
  chartRefs = [],
  setChartRefs = () => {},
  rawData = [],
  dashboardWidgets = [],
  handleLegendItemClick = () => {},
  datasetVisibility = {},
}: DashboardWidgetsProps) {
  const convertToTimeSeries = (data: any) => {
    return data?.map((d: any) => ({ ...d, x: d.x }));
  };

  const [isRenderingPdf, setIsRenderingPdf] = useState(false);
  const [isExpanded, setIsExpanded] = useState<Record<string, unknown>>({});
  const [showFilters, setShowFilters] = useState<Record<string, unknown>>({});

  const [visualisationOption, setVisualisationOption] = useState<
    Record<string, unknown>
  >({});

  useEffect(() => {
    const externalChartRefs: any = [];
    if (dashboardWidgets.length > 0) {
      dashboardWidgets.forEach((widget) => {
        const ref = React.createRef();
        externalChartRefs.push({
          id: widget.id,
          title: widget.name,
          ref: ref,
          complete: () => {
            setIsRenderingPdf(false);
          },
          render: () => {
            setIsRenderingPdf(true);
            setIsExpanded(
              dashboardWidgets.reduce((acc: any, aWidget: any) => {
                acc[aWidget.id] = true;
                return acc;
              }, {})
            );
          },
        });
      });
      setChartRefs(externalChartRefs);
    }
  }, [JSON.stringify(dashboardWidgets.map((widget) => widget.id))]);

  const adjustDatasetsForVisibility = (widget: any) => {
    const visibilitySettings = datasetVisibility[widget.id] || {};

    if (isRenderingPdf) {
      return widget?.data;
    }

    if (widget.type === ChartType.LINE_TIME_SERIES) {
      const lineChartDatasets = widget?.data?.datasets?.map((dataset: any) => {
        if (visibilitySettings[dataset?.label] === false) {
          return { ...dataset, data: [] };
        }
        return dataset;
      });
      return {
        ...widget.data,
        datasets: lineChartDatasets,
      };
    }

    const adjustedDatasets = widget?.data?.datasets
      ?.map((dataset: any) => {
        if (visibilitySettings[dataset?.label] === false) {
          return null;
        }

        const newData = dataset?.data?.map((dataPoint: any, index: any) => {
          const label = widget.data?.labels?.[index];

          return visibilitySettings[label] === false ? 0 : dataPoint;
        });

        return { ...dataset, data: newData };
      })
      .filter((dataset: any) => dataset !== null);

    return {
      ...widget.data,
      datasets: adjustedDatasets,
    };
  };
  return (
    <ul
      role="list"
      className={`grid grid-cols-1 gap-4 items-center sm:grid-cols-1 ${
        Object.values(isExpanded).some((value) => value === true)
          ? 'xl:grid-cols-1  2xl:grid-cols-1'
          : 'lg:grid-cols-3'
      }`}
    >
      {dashboardWidgets
        .filter(
          (widget) =>
            !Object.values(isExpanded).some((value) => value === true) ||
            isExpanded[widget?.id as string]
        )
        .map((widget) => {
          const chartTableData = getChartData(widget?.id, rawData);
          const chart = (
            <li
              key={widget.id ?? widget.name}
              className="rounded-lg bg-white border"
            >
              <div className="flex w-full border-b border-dashed items-center justify-between space-x-6 px-6 py-4">
                <h3 className="truncate text-sm font-medium text-gray-900">
                  {widget.name}
                </h3>
                <div className="flex gap-x-4 bg-gray-50 rounded-lg px-4 py-2">
                  {widget.hasFilters && (
                    <button
                      style={{ zIndex: 9 }}
                      className="px-1 py-1 hover:bg-accent flex items-center justify-center hover:text-white rounded-sm"
                      onClick={() =>
                        setShowFilters({
                          ...showFilters,
                          [widget.id as string]:
                            !showFilters[widget.id as string],
                        })
                      }
                    >
                      <AdjustmentsHorizontalIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  )}
                  <button
                    style={{ zIndex: 9 }}
                    className="px-1 py-1 hover:bg-accent flex items-center justify-center hover:text-white rounded-sm"
                    onClick={() => {
                      const widgetIsExpanded = !isExpanded[widget.id as string];
                      if (!widgetIsExpanded) {
                        setVisualisationOption({
                          ...visualisationOption,
                          [widget.id as string]: 'CHART',
                        });
                      }
                      setIsExpanded({
                        [widget.id as string]: widgetIsExpanded,
                      });
                    }}
                  >
                    {isExpanded[widget.id as string] ? (
                      <ArrowsPointingInIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowsPointingOutIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
              </div>
              <div>
                {widget.filters ? (
                  <div
                    className={`py-4 px-4 text-sm transition-all ease-in-out duration-300 ${
                      showFilters[widget.id as string]
                        ? 'max-h-60 opacity-100'
                        : 'max-h-0 select-none -mt-8 opacity-0'
                    }`}
                  >
                    {widget.filters.options &&
                    widget.filters.options.length > 0 ? (
                      <select
                        className={`text-sm w-full  ${
                          showFilters[widget.id as string] ? '' : 'hidden'
                        }`}
                        value={widget.filters.selectedOption}
                        onChange={(event) =>
                          widget?.filters?.onSelect(event.target.value)
                        }
                      >
                        {widget.filters.options.map((option) => (
                          <option key={option}>{option}</option>
                        ))}
                      </select>
                    ) : null}
                  </div>
                ) : null}
                <div className={`justify-between w-full px-6 py-2`}>
                  <CustomLegend
                    datasetVisibility={datasetVisibility[widget.id as string]}
                    chartData={widget.data}
                    isExpanded={isExpanded[widget.id as string]}
                    onLegendItemClick={(datasetIndex: any) =>
                      handleLegendItemClick(widget.id, datasetIndex)
                    }
                  />
                </div>
                <div
                  className={`flex ${
                    isExpanded[widget.id as string]
                      ? isRenderingPdf
                        ? 'h-[1000px]'
                        : ' h-[500px]'
                      : 'h-[350px]'
                  } pt-6 px-6 items-center transition-all justify-center gap-y-4 divide-x divide-gray-200`}
                >
                  {widget.type === ChartType.PIE && widget.data ? (
                    <div
                      className={`flex items-center ${
                        isExpanded[widget.id as string]
                          ? 'w-[450px] lg:w-[500px] xl:w-[520px] h-[600px]'
                          : ''
                      } justify-center`}
                    >
                      {' '}
                      <Pie
                        // @ts-ignore
                        key={`${widget.id}_key_${isExpanded[widget.id]}`}
                        ref={
                          chartRefs?.find(
                            (chartRef: any) => widget.id === chartRef.id
                          )?.ref
                        }
                        data={adjustDatasetsForVisibility(widget)}
                        options={{
                          animation: isRenderingPdf ? false : undefined,
                          responsive: true, // Make the chart responsive
                          plugins: {
                            legend: {
                              display: false,
                              position: 'top',
                              labels: {
                                // You can specify font properties here
                                font: {
                                  family: 'Poppins', // Change to your desired font family
                                  size: 12, // Change font size as needed
                                },
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  ) : null}
                  {widget.type === ChartType.BAR && widget.data ? (
                    <>
                      <Bar
                        // @ts-ignore
                        key={`${widget.id}_key_${isExpanded[widget.id]}`}
                        data={adjustDatasetsForVisibility(widget)}
                        ref={
                          chartRefs?.find(
                            (chartRef: any) => widget.id === chartRef.id
                          )?.ref
                        }
                        options={{
                          animation: isRenderingPdf ? false : undefined,
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              display: false,
                              position: 'top',
                              labels: {
                                font: {
                                  family: 'Poppins',
                                  size: 12,
                                },
                              },
                            },
                          },
                          indexAxis: 'x',
                          scales: {
                            y: {
                              ...overrideTickFont,
                              grid: {
                                display: isRenderingPdf,
                              },
                            },
                            x: {
                              ...overrideTickFont,
                              grid: {
                                display: isRenderingPdf,
                              },
                            },
                          },
                        }}
                      />
                    </>
                  ) : null}
                  {widget.type === ChartType.LINE_TIME_SERIES && widget.data ? (
                    <Line
                      // @ts-ignore
                      key={`${widget.id}_key_${isExpanded[widget.id]}`}
                      ref={
                        chartRefs?.find(
                          (chartRef: any) => widget.id === chartRef.id
                        )?.ref
                      }
                      data={adjustDatasetsForVisibility({
                        ...widget,
                        data: {
                          ...widget.data,
                          datasets: widget.data?.datasets?.map((ds: any) => ({
                            ...ds,
                            tension: 0.1,
                          })),
                        },
                      })}
                      options={
                        widget.options
                          ? {
                              ...widget.options,
                              animation: isRenderingPdf ? false : undefined,
                            }
                          : {
                              animation: isRenderingPdf ? false : undefined,
                              responsive: true, // Make the chart responsive
                              maintainAspectRatio: false, // Don't maintain the aspect ratio
                              plugins: {
                                legend: {
                                  display: false,
                                  position: 'top',
                                  labels: {
                                    font: {
                                      family: 'Poppins',
                                      size: 12,
                                    },
                                  },
                                },
                              },
                              scales: {
                                x: {
                                  ...overrideTickFont,
                                  type: 'time',
                                  grid: {
                                    display: isRenderingPdf,
                                  },
                                  time: {
                                    parser: 'yyyy-MM',
                                    unit: 'month',
                                    round: 'month',
                                  },
                                },
                                A: {
                                  ...overrideTickFont,
                                  grid: {
                                    display: isRenderingPdf,
                                  },
                                },
                                B: {
                                  position: 'right',
                                  ...overrideTickFont,
                                  grid: {
                                    display: isRenderingPdf,
                                  },
                                },
                              },
                            }
                      }
                    />
                  ) : null}
                  {widget.type === ChartType.STACKED_BAR_TIME_SERIES &&
                  widget.data ? (
                    <Bar
                      // @ts-ignore
                      key={`${widget.id}_key_${isExpanded[widget.id]}`}
                      ref={
                        chartRefs?.find(
                          (chartRef: any) => widget.id === chartRef.id
                        )?.ref
                      }
                      data={adjustDatasetsForVisibility({
                        ...widget,
                        data: {
                          ...widget.data,
                          datasets: widget.data.datasets.map((ds: any) => ({
                            ...ds,
                            data: convertToTimeSeries(ds.data),
                          })),
                        },
                      })}
                      options={
                        widget.options
                          ? {
                              ...widget.options,
                              animation: isRenderingPdf ? false : undefined,
                            }
                          : {
                              animation: isRenderingPdf ? false : undefined,
                              responsive: true, // Make the chart responsive
                              maintainAspectRatio: false, // Don't maintain the aspect ratio
                              plugins: {
                                legend: {
                                  display: false,
                                  position: 'top',
                                  labels: {
                                    font: {
                                      family: 'Poppins',
                                      size: 12,
                                    },
                                  },
                                },
                              },
                              scales: {
                                x: {
                                  ...overrideTickFont,
                                  type: 'time',
                                  stacked: true,
                                  grid: {
                                    display: false,
                                  },
                                  time: {
                                    unit: 'month',
                                    round: 'month',
                                  },
                                },
                                y: {
                                  stacked: true,
                                  ...overrideTickFont,
                                  grid: {
                                    display: false,
                                  },
                                },
                              },
                            }
                      }
                    />
                  ) : null}
                  {widget.type === ChartType.GROUPED_BAR_HORIZONTAL &&
                  widget.data ? (
                    <Bar
                      // @ts-ignore
                      key={`${widget.id}_key_${isExpanded[widget.id]}`}
                      ref={
                        chartRefs?.find(
                          (chartRef: any) => widget.id === chartRef.id
                        )?.ref
                      }
                      data={adjustDatasetsForVisibility(widget)}
                      options={
                        widget.options
                          ? {
                              ...widget.options,
                              animation: isRenderingPdf ? false : undefined,
                            }
                          : {
                              animation: isRenderingPdf ? false : undefined,
                              responsive: true,
                              maintainAspectRatio: false,
                              plugins: {
                                legend: {
                                  display: false,
                                  position: 'top',
                                  labels: {
                                    font: {
                                      family: 'Poppins',
                                      size: 12,
                                    },
                                  },
                                },
                              },

                              indexAxis: 'x',
                              scales: {
                                x: {
                                  ...overrideTickFont,
                                  grid: {
                                    display: false,
                                  },
                                },
                                y: {
                                  ...{ ticks: { ...overrideTickFont.ticks } },
                                  grid: {
                                    display: false,
                                  },
                                },
                              },
                            }
                      }
                    />
                  ) : null}
                </div>
                {isExpanded[widget.id as string] ? (
                  <div
                    className={`flex ${
                      isExpanded[widget.id as string] ? ' h-full' : 'h-[300px]'
                    } pt-6 px-6 items-center transition-all justify-center divide-x divide-gray-200`}
                  >
                    <Table
                      headerMapping={chartTableData?.headerMapping}
                      data={chartTableData?.data}
                    />
                  </div>
                ) : null}
              </div>
              <div className="text-xs text-gray-500 px-4 py-4">
                {widget.description}
              </div>
            </li>
          );
          return chart;
        })}
    </ul>
  );
}
