import { FirebaseAuthProvider } from './FirebaseAuthContext';
import { NotificationProvider } from './NotificationContext';
import React from 'react';

interface ContextWrapperProps {
  children: React.ReactElement;
}

export const ContextWrapper = ({ children }: ContextWrapperProps) => {
  return (
    // @ts-ignore
    <FirebaseAuthProvider>
      <NotificationProvider>{children}</NotificationProvider>
    </FirebaseAuthProvider>
  );
};
