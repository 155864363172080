import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

export interface PaginationProps {
  goToPage: (pageNumber: number) => void;
  total: number;
  pageSize: number;
  currentPage: number;
}

export default function Pagination({
  goToPage,
  total,
  pageSize,
  currentPage,
}: PaginationProps) {
  const totalPages = Math.ceil(total / pageSize);
  const maxPageButtons = 3;

  const renderPageButton = (pageNumber: number) => (
    <button
      key={pageNumber}
      onClick={() => goToPage(pageNumber)}
      className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${
        currentPage === pageNumber
          ? 'bg-primary text-white'
          : 'text-gray-700 hover:bg-gray-200'
      }`}
    >
      {pageNumber}
    </button>
  );

  const Ellipse = () => (
    <span
      key="ellipsis1"
      className="relative inline-flex items-center w-10 justify-center px-2 py-2 text-gray-500 bg-white border border-gray-300"
    >
      ...
    </span>
  );

  const renderPageNumbers = () => {
    let pages = [];

    pages.push(renderPageButton(1));

    let startMiddlePages, endMiddlePages;
    if (currentPage - maxPageButtons > 2) {
      pages.push(<Ellipse />);
      startMiddlePages = Math.max(2, currentPage - maxPageButtons);
    } else {
      startMiddlePages = 2;
    }

    if (currentPage + maxPageButtons < totalPages - 1) {
      endMiddlePages = Math.min(totalPages - 1, currentPage + maxPageButtons);
    } else {
      endMiddlePages = totalPages - 1;
    }

    for (let i = startMiddlePages; i <= endMiddlePages; i++) {
      pages.push(renderPageButton(i));
    }

    if (currentPage + maxPageButtons < totalPages - 1) {
      pages.push(<Ellipse />);
    }

    if (totalPages > 1) {
      pages.push(renderPageButton(totalPages));
    }

    return pages;
  };

  return (
    <div className="flex items-end justify-between py-2 w-full pb-2 pt-10 gap-y-2">
      <div>
        <p className="text-xs text-gray-700">
          Showing {(currentPage - 1) * pageSize + 1} to{' '}
          {Math.min(currentPage * pageSize, total)} of {total} results
        </p>
      </div>
      <nav
        className="isolate inline-flex -space-x-px rounded-md shadow-sm"
        aria-label="Pagination"
      >
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage <= 1}
          aria-label="Previous"
          className="relative inline-flex items-center px-2 py-2 disabled:bg-gray-100 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100"
        >
          <ChevronLeftIcon className="h-5 w-5" />
        </button>

        {renderPageNumbers()}
        <button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage >= totalPages}
          aria-label="Next"
          className="relative inline-flex items-center px-2 py-2 disabled:bg-gray-100 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100"
        >
          <ChevronRightIcon className="h-5 w-5" />
        </button>
      </nav>
    </div>
  );
}
