import { ChartEnum } from '../../types/Charts';

export const getChartData = (chartEnum: string, rawData: any) => {
  let chartData = {
    data: {},
    headerMapping: {},
  };

  switch (chartEnum) {
    case ChartEnum.TOTAL_EXPENDITURE:
      chartData = {
        data: rawData?.getEmissionsData?.TotalExpenditurePerKgCO2e,
        headerMapping: {
          totalEmissions: 'Total Emissions',
          transportEmissions: 'Transport Emissions',
          productEmissions: 'Product Emissions',
          totalExpenditure: 'Total Expenditure',
          purchaseMonth: 'Purchase Month',
        },
      };
      break;
    case ChartEnum.EMISSIONS_BY_MANUFACTURER:
      chartData = {
        data: rawData?.getEmissionsData?.EmissionsByManufacturer,
        headerMapping: {
          manufacturer: 'Manufacturer',
          totalEmissions: 'Total Emissions',
          transportEmissions: 'Transport Emissions',
          productEmissions: 'Product Emissions',
        },
      };
      break;
    case ChartEnum.EMISSIONS_BY_SUPPLIER:
      chartData = {
        data: rawData?.getEmissionsData?.EmissionsBySupplier,
        headerMapping: {
          name: 'Supplier Name',
          totalEmissions: 'Total Emissions',
          transportEmissions: 'Transport Emissions',
          productEmissions: 'Product Emissions',
        },
      };
      break;
    case ChartEnum.EMISSIONS_FROM_TRANSPORT_BY_SUPPLIER:
      chartData = {
        data: rawData?.getEmissionsData?.EmissionsTransportBySupplier,
        headerMapping: {
          modeOfTransport: 'Mode of Transport',
          name: 'Supplier Name',
          totalEmissions: 'Total Emissions',
          transportEmissions: 'Transport Emissions',
          productEmissions: 'Product Emissions',
        },
      };
      break;
    case ChartEnum.EMISSIONS_BY_SECTOR:
      chartData = {
        data: rawData?.getEmissionsData?.EmissionsBySector,
        headerMapping: {
          name: 'Sector Name',
          totalEmissions: 'Total Emissions',
          transportEmissions: 'Transport Emissions',
          productEmissions: 'Product Emissions',
        },
      };
      break;
    case ChartEnum.EMISSIONS_BY_SUB_SECTOR:
      chartData = {
        data: rawData?.getEmissionsData?.EmissionsBySubSector,
        headerMapping: {
          sectorName: 'Sector Name',
          subSectorName: 'Sub-Sector Name',
          totalEmissions: 'Total Emissions',
          transportEmissions: 'Transport Emissions',
          productEmissions: 'Product Emissions',
        },
      };
      break;
    default:
      break;
  }

  return chartData;
};
