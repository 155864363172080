import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useContext, useEffect, useState } from 'react';
import { auth } from '../utils/firebaseConfig';
import { FirebaseAuthContext } from '../context/FirebaseAuthContext';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:4001/graphql';

export const useApolloClient = () => {
  const defaultClient = new ApolloClient({
    cache: new InMemoryCache(),
  });

  const [apolloClient, setApolloClient] = useState(defaultClient);
  const { user } = useContext(FirebaseAuthContext);

  async function createApolloClient() {
    const token = await auth.currentUser?.getIdToken();
    
    const httpLink = new HttpLink({ uri: API_URL });
    
    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }));

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache({
        addTypename: false,
      }),
    });
    
    setApolloClient(client);
  }

  useEffect(() => {
    createApolloClient();
  }, [user.isAuthenticated]);

  return apolloClient;
};