import React from 'react';

export const Card = ({ children }: any) => {
  return (
    <div className="flex flex-col h-full mt-2">
      <div className="mx-8 grow">
        <div className="divide-y divide-gray-200 rounded-lg bg-white shadow">
          {children}
        </div>
      </div>
    </div>
  );
};

export const Header = ({ children }: any) => {
  return <div className="px-4 py-5 sm:px-6">{children}</div>;
};

export const Body = ({ children }: any) => {
  return (
    <div className="px-4 py-5 sm:p-6 overflow-y-visible overflow-visible">
      {children}
    </div>
  );
};

Card.Header = Header;
Card.Body = Body;
