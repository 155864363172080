import { gql } from '@apollo/client';

export const createUser = gql`
  mutation CreateUser($activeOrganisation: String, $input: CreateUserInput) {
    createUser(activeOrganisation: $activeOrganisation, input: $input) {
      id
      success
      message
    }
  }
`;
