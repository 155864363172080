import { gql } from '@apollo/client';

export const getData = gql`
  query GetData(
    $activeOrganisation: String
    $input: GenericFindOptions
    $dateSelection: DateSelection
    $filter: GenericFilterOptions
  ) {
    getData(
      activeOrganisation: $activeOrganisation
      input: $input
      dateSelection: $dateSelection
      filter: $filter
    ) {
      count
      results {
        id
        import {
          id
          name
          fileName
          createdAt
        }
        quantity
        unitPrice
        dateOfPurchase
        emissionProduct
        emissionTransport
        emissionTotal
        intensityRatio
        intensityRatioUnit
        modeOfTransport
        emissionsUnit
        supplierLocation
        product {
          id
          description
          sector {
            id
            name
          }
          subSector {
            id
            name
          }
          manufacturer {
            id
            name
          }
          supplier {
            id
            name
          }
        }
      }
      response {
        message
        success
      }
    }
  }
`;
