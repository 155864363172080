import { StyleSheet, Text, View } from '@react-pdf/renderer';

const createColumnStyles = (numColumns: number) => {
  const width = `${100 / numColumns}%`;
  let columnStyles = {};
  for (let i = 1; i <= numColumns; i++) {
    // @ts-ignore
    columnStyles[`col${i}`] = { width, fontSize: 8 };
  }
  return columnStyles;
};

const Table = ({ data, headerMapping }: any) => {
  if (!data || !data.length) {
    return <Text>No data available</Text>;
  }

  const headers = Object.keys(data[0]);
  const numColumns = headers.length;
  const dynamicStyles = StyleSheet.create({
    table: {
      width: '100%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 2,
      paddingBottom: 2,
    },
    header: {
      backgroundColor: '#EEE',
      borderTop: 'none',
    },
    bold: {
      fontWeight: 'bold',
    },
    ...createColumnStyles(numColumns),
  });

  return (
    <View style={dynamicStyles.table}>
      <View
        style={[dynamicStyles.row, dynamicStyles.bold, dynamicStyles.header]}
      >
        {headers.map((header, index) => (
          <Text
            key={index}
            style={{
              // @ts-ignore
              ...dynamicStyles[`col${index + 1}`],
              fontSize: 10,
              paddingVertical: 2,
              paddingHorizontal: 2,
            }}
          >
            {headerMapping ? headerMapping[header] : header}
          </Text>
        ))}
      </View>
      {data.map((row: any, rowIndex: number) => (
        <View key={rowIndex} style={dynamicStyles.row}>
          {headers.map((header, index) => (
            <Text
              key={index}
              style={{
                // @ts-ignore
                ...dynamicStyles[`col${index + 1}`],
                paddingHorizontal: 2,
              }}
            >
              {row[header]}
            </Text>
          ))}
        </View>
      ))}
    </View>
  );
};

export default Table;
